<template>
  <div v-if="props.params.node?.rowPinned !== 'bottom'">
    <template v-if="props.params?.value?.cellName === 'Opening Balance'">
      <div
        :class="
          props.params?.node?.group
            ? 'elv-financials-cell-balance-main__Bold elv-financials-cell-balance-main'
            : 'elv-financials-cell-balance-main elv-financials-cell-balance-main__Medium'
        "
      >
        <span style="padding-right: 2px"
          >{{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
          }}{{ formatNumber(Math.abs(props.params?.data?.openingBalanceFC), 2) }}</span
        >
        <span v-if="props.params?.node?.group">
          {{
            props.params?.data?.openingBalanceFC < 0
              ? props.params?.value?.balanceType === 'Dr'
                ? 'Cr'
                : 'Dr'
              : props.params?.value?.balanceType
          }}
        </span>
        <span v-else>{{
          props.params?.data?.openingBalanceFC < 0
            ? props.params?.node?.parent?.data?.balanceType === 'Dr'
              ? 'Cr'
              : 'Dr'
            : props.params?.node?.parent?.data?.balanceType
        }}</span>
      </div>
    </template>
    <template v-else-if="props.params?.value?.cellName === 'Closing Balance'">
      <div
        :class="
          props.params?.node?.group
            ? 'elv-financials-cell-balance-main__Bold elv-financials-cell-balance-main'
            : 'elv-financials-cell-balance-main elv-financials-cell-balance-main__Medium'
        "
      >
        <span style="padding-right: 2px"
          >{{ `${entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `}`
          }}{{ formatNumber(Math.abs(props.params?.data?.closingBalanceFC), 2) }}</span
        >
        <span v-if="props.params?.node?.group">
          {{
            props.params?.data?.closingBalanceFC < 0
              ? props.params?.value?.balanceType === 'Dr'
                ? 'Cr'
                : 'Dr'
              : props.params?.value?.balanceType
          }}
        </span>
        <span v-else
          >{{
            props.params?.data?.closingBalanceFC < 0
              ? props.params?.node?.parent?.data?.balanceType === 'Dr'
                ? 'Cr'
                : 'Dr'
              : props.params?.node?.parent?.data?.balanceType
          }}
        </span>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { formatNumber } from '@/lib/utils'
import { useEntityStore } from '@/stores/modules/entity'

const props: any = defineProps<{ params: any }>()

const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)
</script>
<style lang="scss">
.elv-financials-cell-balance-main {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 13px;
  color: $elv-color-0E1420;
  text-align: right;
  width: 100%;
}

.elv-financials-cell-balance-main__Bold {
  font-family: 'Barlow';
  font-weight: 700;
}

.elv-financials-cell-balance-main__Medium {
  font-family: 'Barlow';
  font-weight: 500;
}
</style>
