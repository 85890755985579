<template>
  <div class="elv-table-operating">
    <template
      v-if="
        props.params.node?.rowPinned !== 'bottom' && props.params.node?.rowPinned !== 'top' && !props.params.node?.group
      "
    >
      <div v-loading="refreshing" class="elv-table-operating-icon">
        <el-tooltip
          effect="dark"
          placement="top"
          popper-class="elv-table-operating-refresh-balance-tips"
          overlay-classname="elv-table-operating-refresh-balance-tips"
          :show-after="500"
          :disabled="disabled"
        >
          <SvgIcon
            name="renew"
            width="18"
            height="18"
            :fill="disabled ? '#c4c9d1' : '#838D95'"
            class="elv-table-operating-refresh-balance"
            :class="disabled ? 'disabled' : 'enabled'"
            @click="onClickRefresh"
          />
          <template #content>
            <div class="elv-table-operating-refresh-balance-tips-content">{{ tooltip }}</div>
          </template>
        </el-tooltip>
      </div>
      <!-- <SvgIcon name="Checkmark" width="18" height="18" fill="#838D95" /> -->
    </template>
  </div>
</template>
<script lang="ts" setup>
import { find } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { useEntityStore } from '@/stores/modules/entity'
import { useAccountStore } from '@/stores/modules/accounts'
import { useGlobalStore } from '@/stores/modules/global/index'

const { t } = useI18n()
const route = useRoute()
const props: any = defineProps<{ params: any }>()
const accountStore = useAccountStore()
const entityStore = useEntityStore()
const globalStore = useGlobalStore()
const refreshing = ref(false)

const projectDetail = computed(() => {
  return globalStore.projectDetail(entityStore.entityDetail?.projectId)
})

const entityId = computed(() => {
  return String(route.params.entityId)
})

const tooltip = computed(() => {
  return t('report.refreshBalanceInfo', {
    total: projectDetail.value?.plan !== 'VIP' ? 100 : 1000,
    left: entityStore.entityDetail?.refreshBalanceLeft
  })
})

const disabled = computed(() => {
  const { value } = props.params
  if (!value.entityAccount?.entityAccountId) {
    return true
  }
  let providerId = accountStore.entityAccountUsedProviderMap[value.entityAccount.entityAccountId]
  if (!providerId) {
    providerId = value.entityAccount.usedProviderId
  }
  const provider = find(value.selectProviderList, { providerId })

  return !provider?.realtimeBalance
})

const onClickRefresh = async () => {
  console.log(props.params)
  if (disabled.value || refreshing.value) {
    return
  }
  try {
    refreshing.value = true
    await accountStore.refreshEntityAccountBalance(entityId.value, props.params.value.entityAccount.entityAccountId)
    ElMessage.warning(t('message.startRefresh'))
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    refreshing.value = false
  }
}
</script>
<style lang="scss">
.elv-report-table-tips-content {
  padding: 16px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 11px;
  color: #0e1420;
}

.elv-table-operating {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 8px;

  .elv-table-operating-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    .circular {
      width: 18px;
      height: 18px;
    }
  }

  .elv-table-operating-refresh-balance {
    cursor: pointer;
  }

  .disabled {
    cursor: not-allowed;
  }

  .enabled {
    &:hover {
      fill: #1753eb;
    }
  }
}

.el-popper.elv-table-operating-refresh-balance-tips {
  padding: 5px 10px 8px 10px;
  width: 200px;
  box-sizing: border-box;
}
</style>
