<template>
  <div class="elv-table-number">
    <!-- 分组下标 -->
    <GroupIndexCell :params="props.params" />
    <span
      v-if="props.params.node?.rowPinned !== 'bottom' && props.params.node?.rowPinned !== 'top'"
      :class="{ 'elv-table-number-group': props.params?.node?.group }"
    >
      <el-tooltip
        :show-arrow="false"
        effect="light"
        placement="top"
        popper-class="elv-table-tips"
        overlay-classname="elv-table-tips"
        :show-after="500"
        :disabled="props.params?.value?.tableType === 'accounts'"
      >
        <div>
          <img v-if="false" src="@/assets/img/reports/table-warning-filled.png" alt="error" />
          <p
            :title="`${`${
              entityDetail?.underlyingCurrency?.sign || `${entityDetail?.underlyingCurrency?.showSymbol} `
            }`}${formatNumberValue.value}`"
            :class="{ isHoldings: props.params.value?.tableType === 'holdings' && !props.params?.node?.group }"
          >
            {{ formatNumberValue.formatValue }}
          </p>
        </div>

        <template #content>
          <div class="elv-table-cell-tips-content">
            {{ t('report.disposalExceedsBalanceInfo', { currency: props.params?.data?.currency }) }}
          </div>
        </template>
      </el-tooltip>
    </span>
    <div
      v-if="
        (props.params.node?.rowPinned === 'bottom' ||
          (props.params.node?.rowPinned === 'top' &&
            (props.params.value?.tableType === 'holdings' || props.params.value?.tableType === 'accounts'))) &&
        (props.params.value?.cellName === 'Fair Value' ||
          props.params.value?.cellName === 'Cost Basis' ||
          props.params.value?.cellName === 'Total')
      "
      class="elv-table-number-total"
    >
      <p :title="`${formatNumberValue.value}`">{{ formatNumberValue.formatValue }}</p>
      <span v-if="props.params.value?.cellName !== 'Total' && props.params.value?.tableType !== 'holdings'">{{
        t('common.sum')
      }}</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import GroupIndexCell from './GroupIndexCell.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { toPercent, formatNumber, fieldValueFormat } from '@/lib/utils'

const props: any = defineProps<{ params: any }>()

const { t } = useI18n()
const entityStore = useEntityStore()
const { entityDetail } = storeToRefs(entityStore)

const cellHeight = computed(() => {
  return props.params.node?.rowPinned === 'bottom' &&
    (props.params.value?.cellName === 'Fair Value' || props.params.value?.cellName === 'Cost Basis')
    ? '52px'
    : 'auto'
})

const cellMarginTop = computed(() => {
  return props.params.node?.rowPinned === 'bottom' &&
    (props.params.value?.cellName === 'Fair Value' || props.params.value?.cellName === 'Cost Basis')
    ? '6px'
    : '0px'
})

const cellJustifyContent = computed(() => {
  return props.params.value?.cellName === 'Transactions' ? 'flex-start' : 'flex-end'
})

const formatNumberValue = computed(() => {
  let formatValue = '-'
  let value = '-'
  switch (props.params.value?.cellName) {
    case 'Nominal Amount':
      value = fieldValueFormat(
        props.params?.data?.amount,
        {
          price: true,
          keepPoint: true,
          symbol: `${
            entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
          }`
        },
        'NUMBER'
      )
      if (props.params?.data?.amount === 0) {
        formatValue = props.params?.data?.amount
      } else {
        formatValue = props.params?.data?.amount ? formatNumber(props.params?.data?.amount, 2) : '-'
      }
      break
    case 'Market Value':
      value = fieldValueFormat(
        props.params?.data?.marketValueFC,
        {
          price: true,
          keepPoint: true,
          symbol: `${
            entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
          }`
        },
        'NUMBER'
      )
      if (props.params?.data?.marketValueFC === 0) {
        formatValue = props.params?.data?.marketValueFC
      } else {
        formatValue = props.params?.data?.marketValueFC
          ? fieldValueFormat(
              props.params?.data?.marketValueFC,
              {
                dollar: true,
                symbol: `${
                  entityDetail.value?.underlyingCurrency?.sign ||
                  `${entityDetail.value?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )
          : '-'
      }
      break
    case '% of total net assets':
      value = props.params?.data?.shareOfNetAssets
      if (props.params?.data?.shareOfNetAssets === 0) {
        formatValue = props.params?.data?.shareOfNetAssets
      } else {
        formatValue = props.params?.data?.shareOfNetAssets ? toPercent(props.params?.data?.shareOfNetAssets, 1) : '-'
      }
      break
    case 'Transactions':
      value = fieldValueFormat(
        props.params?.data?.count,
        {
          price: true,
          keepPoint: true,
          symbol: `${
            entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
          }`
        },
        'NUMBER'
      )
      formatValue = formatNumber(Number(props.params?.data?.count ?? props.params?.data?.transactionCount)) ?? '-'
      break
    case 'IRR':
      value = props.params?.data?.irr
      if (props.params?.data?.irr === 0) {
        formatValue = props.params?.data?.irr
      } else {
        formatValue = props.params?.data?.irr ? toPercent(props.params?.data?.irr, 1) : '-'
      }
      break
    case 'Multiples':
      value = fieldValueFormat(
        props.params?.data?.multiples,
        {
          price: true,
          keepPoint: true,
          symbol: `${
            entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
          }`
        },
        'NUMBER'
      )
      if (props.params?.data?.multiples === 0) {
        formatValue = props.params?.data?.multiples
      } else {
        formatValue = props.params?.data?.multiples ? `${formatNumber(props.params?.data?.multiples, 2)}x` : '-'
      }
      break
    case 'Cost basis':
      value = fieldValueFormat(
        props.params?.data?.costBasisFC,
        {
          price: true,
          keepPoint: true,
          symbol: `${
            entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
          }`
        },
        'NUMBER'
      )
      if (props.params?.data?.costBasisFC === 0) {
        formatValue = props.params?.data?.costBasisFC
      } else {
        formatValue = props.params?.data?.costBasisFC
          ? fieldValueFormat(
              props.params?.data?.costBasisFC,
              {
                dollar: true,
                symbol: `${
                  entityDetail.value?.underlyingCurrency?.sign ||
                  `${entityDetail.value?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )
          : '-'
      }
      break
    case 'Realized Proceeds':
      value = fieldValueFormat(
        props.params?.data?.realizedValueFC,
        {
          price: true,
          keepPoint: true,
          symbol: `${
            entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
          }`
        },
        'NUMBER'
      )
      if (props.params?.data?.realizedValueFC === 0) {
        formatValue = props.params?.data?.realizedValueFC
      } else {
        formatValue = props.params?.data?.realizedValueFC
          ? fieldValueFormat(
              props.params?.data?.realizedValueFC,
              {
                dollar: true,
                symbol: `${
                  entityDetail.value?.underlyingCurrency?.sign ||
                  `${entityDetail.value?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )
          : '-'
      }
      break
    case 'Cost basis of Realized':
      value = fieldValueFormat(
        props.params?.data?.realizedBasisFC,
        {
          price: true,
          keepPoint: true,
          symbol: `${
            entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
          }`
        },
        'NUMBER'
      )
      if (props.params?.data?.realizedBasisFC === 0) {
        formatValue = props.params?.data?.realizedBasisFC
      } else {
        formatValue = props.params?.data?.realizedBasisFC
          ? fieldValueFormat(
              props.params?.data?.realizedBasisFC,
              {
                dollar: true,
                symbol: `${
                  entityDetail.value?.underlyingCurrency?.sign ||
                  `${entityDetail.value?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )
          : '-'
      }
      break
    case 'Quantity':
      value = fieldValueFormat(
        props.params?.data?.quantity,
        {
          price: true,
          keepPoint: true,
          symbol: `${
            entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
          }`
        },
        'NUMBER'
      )
      if (props.params?.data?.quantity === 0) {
        formatValue = props.params?.data?.quantity
      } else {
        formatValue = props.params?.data?.quantity ? formatNumber(props.params?.data?.quantity, 2) : '-'
      }
      break
    case 'Fair Value':
      value = fieldValueFormat(
        props.params?.data?.marketValue,
        {
          price: true,
          keepPoint: true,
          symbol: `${
            entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
          }`
        },
        'NUMBER'
      )
      if (props.params?.data?.marketValue === 0) {
        formatValue = props.params?.data?.marketValue
      } else if (props.params.node?.rowPinned === 'top') {
        value = fieldValueFormat(
          props.params?.data?.fieldValues?.marketValue,
          {
            price: true,
            keepPoint: true,
            symbol: `${
              entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
            }`
          },
          'NUMBER'
        )
        formatValue = props.params?.data?.fieldValues?.marketValue
          ? fieldValueFormat(
              props.params?.data?.fieldValues?.marketValue,
              {
                dollar: true,
                symbol: `${
                  entityDetail.value?.underlyingCurrency?.sign ||
                  `${entityDetail.value?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )
          : '-'
      } else {
        formatValue = props.params?.data?.marketValue
          ? fieldValueFormat(
              props.params?.data?.marketValue,
              {
                dollar: true,
                symbol: `${
                  entityDetail.value?.underlyingCurrency?.sign ||
                  `${entityDetail.value?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )
          : '-'
      }
      break
    case 'Cost Basis':
      value = fieldValueFormat(
        props.params?.data?.costBasis,
        {
          price: true,
          keepPoint: true,
          symbol: `${
            entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
          }`
        },
        'NUMBER'
      )
      if (props.params?.data?.costBasis === 0) {
        formatValue = props.params?.value?.tableType === 'holdings' ? '-' : props.params?.data?.costBasis
      } else if (props.params.node?.rowPinned === 'top') {
        value = fieldValueFormat(
          props.params?.data?.fieldValues?.costBasis,
          {
            price: true,
            keepPoint: true,
            symbol: `${
              entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
            }`
          },
          'NUMBER'
        )
        formatValue = props.params?.data?.fieldValues?.costBasis
          ? fieldValueFormat(
              props.params?.data?.fieldValues?.costBasis,
              {
                dollar: true,
                symbol: `${
                  entityDetail.value?.underlyingCurrency?.sign ||
                  `${entityDetail.value?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )
          : '-'
      } else {
        formatValue = props.params?.data?.costBasis
          ? fieldValueFormat(
              props.params?.data?.costBasis,
              {
                dollar: true,
                symbol: `${
                  entityDetail.value?.underlyingCurrency?.sign ||
                  `${entityDetail.value?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )
          : '-'
      }
      break
    case 'Total':
      value = props.params?.data?.totalFC ?? props.params?.data?.fieldValues?.totalFC
      if (props.params?.data?.totalFC === 0) {
        formatValue = props.params?.data?.totalFC
      } else if (props.params.node?.rowPinned === 'bottom' || props.params.node?.rowPinned === 'top') {
        formatValue = props.params?.data?.fieldValues?.totalFC
          ? fieldValueFormat(
              props.params?.data?.fieldValues?.totalFC,
              {
                dollar: true,
                symbol: `${
                  entityDetail.value?.underlyingCurrency?.sign ||
                  `${entityDetail.value?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )
          : '-'
        value = fieldValueFormat(
          props.params?.data?.fieldValues?.totalFC,
          {
            price: true,
            keepPoint: true,
            symbol: `${
              entityDetail.value?.underlyingCurrency?.sign || `${entityDetail.value?.underlyingCurrency?.showSymbol} `
            }`
          },
          'NUMBER'
        )
      } else {
        formatValue = props.params?.data?.totalFC
          ? fieldValueFormat(
              props.params?.data?.totalFC,
              {
                dollar: true,
                symbol: `${
                  entityDetail.value?.underlyingCurrency?.sign ||
                  `${entityDetail.value?.underlyingCurrency?.showSymbol} `
                }`
              },
              'NUMBER'
            )
          : '-'
      }
      break
    default:
      formatValue = '-'
      break
  }
  return { formatValue, value }
})
</script>
<style lang="scss">
.elv-table-cell-tips-content {
  padding: 10px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 11px;
  color: #0e1420;
  width: 220px;
}

.elv-table-number {
  height: v-bind('cellHeight');
  margin-top: v-bind('cellMarginTop');
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: v-bind('cellJustifyContent');
  font-family: 'Barlow';
  font-weight: 500;
  font-size: 13px;
  color: $elv-color-0E1420;

  p.isHoldings {
    color: #0e0f11;
    font-weight: 400;
  }

  .elv-table-number-group {
    font-weight: 600;
    display: flex;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
    }

    img {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }

  .elv-table-number-total {
    display: flex;
    flex-direction: column;

    p {
      font-family: 'Barlow';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #0e0f11;
      text-align: right;
    }

    span {
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      zoom: 0.83;
      -moz-transform: scale(0.83);
      -moz-transform-origin: top left;
      color: #aaafb6;
      text-align: right;
    }
  }
}
</style>
