<template>
  <div
    v-if="!props.params.node?.group && props.params.node?.rowPinned !== 'top'"
    class="elv-financials-cell-dataSource-provider"
  >
    <el-tooltip
      effect="dark"
      placement="top"
      popper-class="elv-financials-cell-dataSource-provider-tips"
      overlay-classname="elv-financials-cell-dataSource-provider-tips"
      :show-after="500"
      :disabled="!currentProvider"
    >
      <div class="elv-financials-cell-dataSource-provider__value">
        <div v-if="computedOptions?.length" class="elv-financials-cell-dataSource-provider__value-select">
          <el-select
            v-model="currentProviderId"
            placeholder=" "
            popper-class="elv-financials-cell-dataSource-provider__value-select-popper"
            :suffix-icon="CustomIcon"
            @change="onSelectChange"
          >
            <el-option v-for="item in computedOptions" :key="item.value" :label="item.label" :value="item.value">
              <img v-if="item.icon" :src="item.icon" />
              {{ item.label }}</el-option
            >
            <template #prefix>
              <img v-if="currentSelectedData?.icon" :src="currentSelectedData?.icon" />
              <div class="elv-select__prefix-label">
                <p>{{ currentSelectedData?.label }}</p>
                <span
                  v-if="props.params?.data?.entityAccount?.syncDatetime && currentSelectedData?.label !== 'Calculated'"
                  >Updated at
                  {{
                    dayjs
                      .tz(props.params?.data?.entityAccount?.syncDatetime, entityStore.entityDetail?.timezone ?? 'UTC')
                      .format('MM/DD HH:mm:ss')
                  }}</span
                >
              </div>
            </template>
          </el-select>
        </div>
      </div>
      <template #content>
        <div class="elv-financials-cell-dataSource-provider-tips-content">{{ tooltip }}</div>
      </template>
    </el-tooltip>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { find } from 'lodash-es'
import utc from 'dayjs/plugin/utc'
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import timezone from 'dayjs/plugin/timezone'
import { useEntityStore } from '@/stores/modules/entity'
import { useAccountStore } from '@/stores/modules/accounts'
import CustomIcon from '../DataSourceProviderCustomIcon.vue'

dayjs.extend(utc)
dayjs.extend(timezone)

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const accountStore = useAccountStore()

const currentProviderId = ref(props.params.value.entityAccount?.usedProviderId)

const entityId = computed(() => {
  return String(route.params.entityId)
})

const computedOptions = computed(() => {
  const providerList = props.params.value.selectProviderList || []
  return providerList.map((item: any) => {
    return {
      value: item.providerId,
      label: item.name,
      icon: item.logo
    }
  })
})

const currentSelectedData = computed(() => {
  return find(computedOptions.value, { value: currentProviderId.value }) || {}
})

const currentProvider: any = computed(() => {
  return (props.params.value.selectProviderList || []).find((item: any) => item.providerId === currentProviderId.value)
})

const tooltip = computed(() => {
  switch (currentProvider.value?.slug) {
    case 'calculated':
      return t('report.calculatedBalance')
    default:
      return t('report.syncedBalance', { source: currentProvider.value?.name })
  }
})

const onSelectChange = async (value: any) => {
  try {
    const { entityAccountUsedProviderMap, setEntityAccountUsedProviderMap, switchEntityAccountProvider } = accountStore
    setEntityAccountUsedProviderMap({
      ...entityAccountUsedProviderMap,
      [props.params.value.entityAccount.entityAccountId]: value
    })

    await switchEntityAccountProvider(entityId.value, props.params.value.entityAccount.entityAccountId, value)
    ElMessage.success(t('message.success'))
    emitter.emit('resetAccountList')
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  }
}
</script>

<style lang="scss">
.elv-financials-cell-dataSource-provider {
  width: 100%;
  display: flex;
  align-items: stretch;

  .elv-financials-cell-dataSource-provider__value {
    width: 100%;
  }

  .elv-financials-cell-dataSource-provider__value-select {
    .el-select {
      width: 100%;
      display: flex;
      align-items: center;

      .el-select__wrapper {
        height: fit-content;
        min-height: 15px;
        border: none !important;
        box-shadow: none !important;
        padding: 0;

        .el-select__selection {
          display: none;
        }

        .el-select__prefix {
          display: flex;
          align-items: center;
          width: 135px;
          min-height: 18px;

          img {
            width: 18px;
            height: 18px;
            display: block;
          }

          .elv-select__prefix-label {
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
              color: #0e0f11;
              font-family: 'Plus Jakarta Sans';
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 13px;
            }

            span {
              color: #aaafb6;
              font-family: 'Barlow';
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: 12px;
            }
          }
        }

        .el-select__suffix {
          .el-icon {
            margin-left: 4px;

            svg {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }
}

.elv-financials-cell-dataSource-provider__value-select-popper {
  .el-select-dropdown__item {
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    img {
      width: 18px;
      height: 18px;
    }
  }
}

.el-popper.elv-financials-cell-dataSource-provider-tips {
  padding: 5px 10px 8px 10px;
  width: 200px;
  box-sizing: border-box;
}
</style>
